import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { combineLatest, EMPTY, interval, Observable } from 'rxjs';
import { catchError, map, share, skipWhile, switchMap, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { settingsSelectors, State } from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';
import { isNil } from 'lodash-es';

const RECONNECT_INTERVAL = 1000;

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private connectionPreviouslyFailed = false;

  private websocketUrl$: Observable<string> = combineLatest([
    this.store.pipe(select(settingsSelectors.websocketUrl)),
    this.store.pipe(select(authenticationSelectors.getIdToken), skipWhile(isNil)),
  ]).pipe(
    map(([websocketUrl, token]) => `${websocketUrl}?authorization=${token}`),
    take(1),
  );

  private socket$: WebSocketSubject<{ message: string }>;

  public connect$ = this.connect().pipe(share());

  constructor(private store: Store<State>) {}

  connect(): Observable<{ message: string }> {
    return this.websocketUrl$.pipe(
      switchMap((url) => {
        this.socket$?.complete();
        this.socket$ = this.getNewWebSocket(url);
        return this.socket$;
      }),
      catchError(() =>
        interval(RECONNECT_INTERVAL).pipe(
          take(1),
          switchMap(() => {
            if (!this.connectionPreviouslyFailed) {
              this.connectionPreviouslyFailed = true;
              return this.connect();
            } else {
              return EMPTY;
            }
          }),
        ),
      ),
    );
  }

  sendMessage(msg: { message: string }) {
    this.socket$.next(msg);
  }

  close() {
    this.socket$.complete();
  }

  private getNewWebSocket(url: string) {
    return webSocket<{ message: string }>(url);
  }
}
