import { Injectable } from '@angular/core';
import { ApiGatewayService } from '@wam/authentication';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { WithAuthService } from '@app/shared/services/with-auth.service';
import { select, Store } from '@ngrx/store';
import { State } from './state/organizations.state';
import { Configuration, Job, JobSchedule, JobStatusEnum } from '@wap/batch';
import { isEmpty } from 'lodash-es';
import { documentId, RosteringProvider } from '@wam/shared';
import { authenticationSelectors } from '@app/authentication/state/authentication.selectors';
import { CourseAssignmentsPayload } from '@app/organizations/batch.model';

export enum JobTypeEnum {
  REMOVE_ASSOCIATIONS = 'remove_associations',
  RESET_TO_PLACEMENT = 'resetplacement',
  UNASSIGN_COURSES = 'course_unassignment',
  COURSE_ASSIGNMENT = 'course_assignment',
}

export const IN_QUEUE = 'in_queue' as JobStatusEnum;

@Injectable({
  providedIn: 'root',
})
export class BatchService extends WithAuthService {
  constructor(
    protected store: Store<State>,
    private apiGatewayService: ApiGatewayService,
  ) {
    super(store);
  }

  displayRostering$ = (organizationId?: string): Observable<boolean> => {
    const provider$ = organizationId
      ? this.getRosteringProvider(organizationId)
      : this.store.pipe(select(authenticationSelectors.getRosteringProvider), take(1));
    return provider$.pipe(
      map((provider) =>
        [RosteringProvider.CLEVER, RosteringProvider.ONE_ROSTER].includes(provider),
      ),
    );
  };

  scheduleJob(
    jobType: JobTypeEnum | RosteringProvider,
    jobConfiguration: {
      hour: string;
      recurrent: boolean;
      paused: boolean;
      configuration?: unknown;
    },
    organization?: string,
  ): Observable<JobSchedule> {
    return this.withUser().pipe(
      mergeMap((user) => {
        return this.apiGatewayService.put<JobSchedule>(
          `batch/v1/sources/${organization ?? user.organization}/types/${jobType}/schedule/jobs`,
          jobConfiguration,
          'scheduledJobs',
        );
      }),
      map((job) => ({ ...job, status: IN_QUEUE })),
    );
  }

  getJob(
    jobType: JobTypeEnum | RosteringProvider,
    organizationId?: string,
  ): Observable<Job & JobSchedule> {
    return this.withUser().pipe(
      mergeMap((user) => {
        return forkJoin([
          this.apiGatewayService
            .get<JobSchedule>(
              `batch/v1/sources/${
                organizationId ?? user.organization
              }/types/${jobType}/schedule/jobs`,
            )
            .pipe(
              catchError((error) => {
                if (error.status === 404) {
                  return of({} as JobSchedule);
                }
                throw error;
              }),
            ),
          this.getLastJob(jobType, organizationId ?? user.organization),
        ]).pipe(
          mergeMap(([scheduledJob, latestJob]) => {
            const combinedJob = { ...scheduledJob, ...latestJob };
            if (!isEmpty(combinedJob)) {
              combinedJob.status = combinedJob.status ?? IN_QUEUE;
            }
            return of(combinedJob);
          }),
        );
      }),
    );
  }

  getLastJob(
    jobType: JobTypeEnum | RosteringProvider,
    organization: string,
    status?: string,
  ): Observable<Job> {
    const extraParam = status ? `?status=${status}` : '';
    return this.apiGatewayService
      .get<Job[]>(`batch/v1/sources/${organization}/types/${jobType}/jobs${extraParam}`)
      .pipe(
        map((jobs) => jobs[0]),
        catchError((error) => {
          if (error.status === 404) {
            return of(null);
          }
          throw error;
        }),
      );
  }

  getLastSuccessfulJob(jobType: JobTypeEnum, organization?: string): Observable<Job> {
    return this.withUser().pipe(
      mergeMap((user) => this.getLastJob(jobType, organization ?? user.organization, 'success')),
    );
  }

  updateScheduledJob(
    jobType: JobTypeEnum | RosteringProvider,
    updates: { hour: string; recurrent: boolean; paused: boolean },
    organization?: string,
  ): Observable<JobSchedule> {
    return this.withUser().pipe(
      mergeMap((user) =>
        this.apiGatewayService.put<JobSchedule>(
          `batch/v1/sources/${organization ?? user.organization}/types/${jobType}/schedule/jobs`,
          updates,
          'scheduledJobs',
        ),
      ),
    );
  }

  startJob(
    jobType: JobTypeEnum | RosteringProvider,
    products?: string[],
    dryRun?: boolean,
    organization?: string,
  ): Observable<Job> {
    return this.withUser().pipe(
      mergeMap((user) => {
        return dryRun
          ? this.apiGatewayService.post<Job>(
              `batch/v1/sources/${organization ?? user.organization}/types/${jobType}/jobs`,
              { configuration: { testMode: true } },
              'configurations',
            )
          : this.apiGatewayService.post<Job>(
              `batch/v1/sources/${organization ?? user.organization}/types/${jobType}/jobs`,
              { runConfiguration: { products } },
            );
      }),
    );
  }

  startUnassignCoursesJob(courses: string[]): Observable<Job> {
    const assignments = courses.map((course) => ({ documentId: documentId(course) }));
    return this.withUser().pipe(
      mergeMap((user) =>
        this.apiGatewayService.post<Job>(
          `batch/v1/sources/${user.organization}/types/${JobTypeEnum.UNASSIGN_COURSES}/jobs`,
          {
            configuration: { sourceId: user.organization },
            runConfiguration: { organization: user.organization, assignments, noEmail: true },
          },
        ),
      ),
    );
  }

  startBatchUnassignCourses(
    schoolUUID: string,
    assignments: { documentId: string; grades: string[] }[],
  ) {
    return this.withUser().pipe(
      mergeMap((user) =>
        this.apiGatewayService.post<Job>(
          `batch/v1/sources/${user.organization}/types/${JobTypeEnum.UNASSIGN_COURSES}/jobs`,
          {
            configuration: { sourceId: user.organization },
            runConfiguration: {
              organization: user.organization,
              school: schoolUUID,
              user: {
                email: user.email,
                fullName: user.name,
              },
              assignments,
            },
          },
        ),
      ),
    );
  }

  startUpdateSessionTimeJob(
    schoolId: string,
    assignments: CourseAssignmentsPayload[],
  ): Observable<Job> {
    return this.withUser().pipe(
      mergeMap((user) => {
        return this.apiGatewayService.post<Job>(
          `batch/v1/sources/${user.organization}/types/${JobTypeEnum.COURSE_ASSIGNMENT}/jobs`,
          {
            runConfiguration: {
              organization: user.organization,
              school: schoolId,
              user: {
                email: user.email,
                fullName: user.name,
              },
              assignments,
            },
          },
        );
      }),
    );
  }

  getConfigurationForType(
    type: RosteringProvider,
    organization?: string,
  ): Observable<Configuration> {
    return this.withUser().pipe(
      mergeMap((user) =>
        this.apiGatewayService.get<Configuration>(
          `batch/v1/sources/${organization ?? user.organization}/types/${type}/configurations`,
        ),
      ),
      catchError(() => of({} as Configuration)),
    );
  }

  getConfiguration(organization: string): Observable<Configuration> {
    return combineLatest([
      this.getConfigurationForType(RosteringProvider.CLEVER, organization),
      this.getConfigurationForType(RosteringProvider.ONE_ROSTER, organization),
      this.getConfigurationForType(RosteringProvider.ONE_ROSTER_SFTP, organization),
    ]).pipe(
      map(([cleverConfiguration, oneRosterConfiguration, oneRosterConfigurationSFTP]) => {
        if (!isEmpty(cleverConfiguration)) {
          return cleverConfiguration;
        }
        if (!isEmpty(oneRosterConfiguration)) {
          return oneRosterConfiguration;
        }
        if (!isEmpty(oneRosterConfigurationSFTP)) {
          return oneRosterConfigurationSFTP;
        }
        return null;
      }),
    );
  }

  getRosteringProvider(organization?: string): Observable<RosteringProvider> {
    return this.getConfiguration(organization).pipe(
      map(
        (configuration) =>
          (configuration?.type as RosteringProvider) ?? RosteringProvider.WATERFORD,
      ),
    );
  }

  saveConfiguration(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    configuration: any,
    type: RosteringProvider,
    organization?: string,
  ): Observable<Configuration> {
    return this.withUser().pipe(
      mergeMap((user) =>
        this.apiGatewayService.put<Configuration>(
          `batch/v1/sources/${organization ?? user.organization}/types/${type}/configurations`,
          configuration,
          'configurations',
        ),
      ),
    );
  }

  getJobs(provider: RosteringProvider, organizationId?: string): Observable<Job[]> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.get<Job[]>(
          `batch/v1/sources/${organizationId ?? organization}/types/${provider}/jobs`,
        ),
      ),
    );
  }

  getJobStatus(
    jobId: string,
    provider: RosteringProvider,
    organizationId?: string,
  ): Observable<Job> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService.get<Job>(
          `batch/v1/sources/${organizationId ?? organization}/types/${provider}/jobs/${jobId}`,
        ),
      ),
    );
  }

  getJobLogsLocation(organizationId: string, logsUrl: string) {
    return this.apiGatewayService.get(
      `batch/v1/sources/${organizationId}/url/${encodeURIComponent(logsUrl)}`,
    );
  }

  isResetToPlacementScheduled$(organizationId?: string): Observable<boolean> {
    return this.withUser().pipe(
      mergeMap(({ organization }) =>
        this.apiGatewayService
          .get<JobSchedule>(
            `batch/v1/sources/${organizationId ?? organization}/types/resetplacement/schedule/jobs`,
          )
          .pipe(
            map((job) => true),
            catchError((error) => {
              if (error.status === 404) {
                return of(false);
              }
              throw error;
            }),
          ),
      ),
    );
  }
}
